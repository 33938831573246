.FilmsBadges {
    .badge {
        margin: 0 5px 8px 0;
    }
    .woman {
        background-color: rgb(149, 4, 197) !important;
    }
    .disabled {
        & {
            border: 1px solid #202020;
            background-color: transparent !important;
            color: #202020;
            opacity: .3;

        }
        &:after {
            content: '?';
        }
    }
}

