.Landing {
    flex-wrap: wrap;
    margin-bottom: 200px;
    .card {
        min-width: 20%;
        border-radius: 0;
        border: 0;
        margin: 0 8px 8px 0;
        overflow: hidden;
        &:hover {
            .text-muted {
                color: var(--bs-card-title-color) !important;
            }
            .card-link {
                border-color: var(--bs-card-title-color) !important;
            }
        }
        &.disabled {
            .card-link {
                opacity: .3;
            }
            .badge {
                position: absolute;
                font-size: 1rem;
                z-index: 100;
                transform: rotate(25deg);
                top: 50%;
                margin-top:-12%;
                left: 50%;
                margin-left: -35%;
                box-shadow: 0 0 10px rgba(0,0,0,.5);
            }
        }

    }
    .card-link {
        border-radius: 0;
        border: 1px solid rgba(0,0,0,.8);
        position: relative;
        padding-bottom: 370px;
        img {
            border-radius: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .badge {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .card-text {
        margin-bottom: 10px;
    }
    .card-body {
        position: absolute;
        bottom: 0;
        background: rgba(255,255,255,.8);
        min-height: 50%;
        width: 100%;

    }
    .card-title {
        font-weight: bold;
    }
    .text-muted {
        color: #000 !important;
    }
}