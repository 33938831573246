
.Loading {
    & {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 99;
      background: #FFF;
      text-align: center;
      top: 0;
      left: 0;
    }
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 300px;
    }
    img {
      margin: 0 auto;
      padding-left: 23%;
      max-width: 100%;
      margin-bottom: 20px;
    }
    h1 {
      margin: 0;
      padding: 0 0 100px;
      color: #8e8a8a
      ;
    }
  }