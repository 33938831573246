
.Navigation {
    & {
        height: 70px;
    }
    .navbar-brand {
        & {
            margin-top: 0;
            padding-top: 0;
        }
        img {
            width: 70px;
            margin: 0;
        }
    }
    .navbar {
        box-shadow: 0px 3px  5px rgba(0,0,0,.3);
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;
        z-index: 1000;
        background: #FFF;
        padding-bottom: 0;
        .navbar-nav {
        margin-right: 0 !important;
        }
    }
    .active {
        color: #FFF !important;
    }
    .navbar-toggler {
        border: 0px none;
        box-shadow: 0px 0px;
        margin-top: 0;
        padding-top: 0;
    }
    .navbar-collapse {
        margin-bottom: 5px;
    }
    .btn-outline-danger {
        margin: 0 0 5px 10px;
    }
    .nav-link {
        margin: 0 10px;
        padding-left: 10px;
    }
    .dropdown {
        button {
            width: auto;
            text-align: left;
            background: transparent;
            box-shadow: 0px none;
            border: 0px none;
            margin: 0 10px;
        }
    }
    a.dropdown-item {
        &:hover {
            color: var(--bs-nav-pills-link-active-bg) !important;
        }
    }
    .navbar-light .navbar-nav .nav-link {
        color: #000;
        &:hover {
            background-color: #dae0e5;
        }
    }
}

